import {useContext, useEffect, useRef, useState} from "react";
import {PlannerContext} from "../components/TeamPlanners";
import {dayDiff, getIsoDate} from "../lib/DateTools";

/**
 * 
 * @param {TeamTaskInterface} task
 * @param {TeamMemberInterface} teamMember
 * @param ref
 * @return {{endDrag: endDrag, startDrag: startDrag}}
 */
export default function useTaskDrag(task, teamMember, ref) {
    let dragTimeout;
    const dummy = useRef();
    const [isDrag, setIsDrag] = useState(false);
    const plannerContext = useContext(PlannerContext);

    useEffect( () => {
        if (ref.current) {
            if (isDrag) {
                ref.current.classList.add('frozen')
            } else {
                ref.current.classList.remove('frozen')
            }
        }
        return () => {
            if (!ref.current) return;
            ref.current.classList.remove('frozen')
        }
    }, [isDrag]);

    useEffect(() => detachEvents, []);

    /** @param {MouseEvent} e */
    function startDrag(e) {
        e.stopPropagation();
        e.preventDefault();
        document.addEventListener('mouseup', dragEnd);
        dragTimeout = setTimeout( () => {
            setIsDrag(true);
            dummy.current = createDummyTask(ref.current, e.clientX, e.clientY, (e.nativeEvent || e).offsetX, (e.nativeEvent || e).offsetY);
            if (!dummy.current) return;

            plannerContext.setDragTask({
                node: ref.current,
                task,
                sourceTeam: teamMember.teamId,
                sourceVisibleTeam: ref.current?.closest('[data-team-id]')?.dataset.teamId,
                sourceVisibleTeamMember: ref.current?.closest('[data-member-id]')?.dataset.memberId,
                grabbedDayIndex: countGrabbedDayIndex(e)
            });

            document.addEventListener('mousemove', dragTask);
            document.addEventListener('keyup', dragTerminate);
        }, 200)
    }

    function endDrag(e) {
        if (isDrag) e.stopPropagation()
    }

    function countGrabbedDayIndex(e) {
        if (!task.span || task.span === 1) return 0;

        const target = e.target;
        const leftMargin = target.offsetLeft;

        const clickX = (e.nativeEvent || e).offsetX + leftMargin;
        const cellWidth = target.parentElement.offsetWidth;
        const visibleDayNumber = Math.floor(clickX / cellWidth);
        const firstCellDate = ref.current.parentElement.dataset.date;
        const firstRenderedDay = dayDiff(getIsoDate(task.startDate), firstCellDate);
        return firstRenderedDay+visibleDayNumber;
    }

    function createDummyTask(sourceElement, x, y, offsetX, offsetY) {
        const dummy = sourceElement?.cloneNode(true);
        if (!dummy) return;
        dummy.classList.add('dummy');
        dummy.style.width = sourceElement.offsetWidth + 'px';
        dummy.style.height = sourceElement.offsetHeight + 'px';
        dummy.style.transform = `translate(-${offsetX}px, -${offsetY}px)`;
        dummy.style.left = x + 'px';
        dummy.style.top = y + 'px';
        sourceElement.parentElement.appendChild(dummy);
        return dummy;
    }

    function dragTask(e) {
        if (!dummy.current) return;
        dummy.current.style.left = e.clientX + 'px';
        dummy.current.style.top = e.clientY + 'px';
    }

    function dragEnd(e) {
        e.stopPropagation();
        setIsDrag(false);
        if (dragTimeout) clearTimeout(dragTimeout);
        detachEvents();
        if (!dummy.current) return;
        dummy.current.parentElement.removeChild(dummy.current);
        dummy.current = null;
    }

    function dragTerminate(e) {
        if (!dummy.current) return;
        if (e.key === 'Escape') {
            dummy.current.parentElement.removeChild(dummy.current);
            dummy.current = null;
            setIsDrag(false);
            plannerContext.setDragTask(null);
            detachEvents();
        }
    }

    function detachEvents() {
        document.removeEventListener('mousemove', dragTask);
        document.removeEventListener('mouseup', dragEnd);
    }

    return {startDrag, endDrag}

}



