import {useEffect, useState} from "react";

export default function useSystemUsers() {
    const [users, setUsers] = useState([]);

    useEffect( () => {
        async function fetchData() {
            const resp = await fetch('/api/users/index');
            if (!resp.ok) throw new Error('Nie mogę pobrać listy użytkowników');
            const data = await resp.json();
            setUsers(data.filter(u => u.isActive));
        }
        fetchData();
        }, []
    );

    return {users};
}
