import React, {useEffect, useState} from "react";
import BoxWrapper from "./BoxWrapper";
import useLoggedInUser from "../hooks/useLoggedInUser";
import BoxTeamPlanner from "./BoxTeamPlanner";
import useSystemUsers from "../lib/useSystemUsers";
import {Bounce, ToastContainer} from "react-toastify";
import {Tooltip} from "react-tooltip";
import {PlannerContext} from "./TeamPlanners";

export default function BoxMyPlanner({expanded, onExpandToggle}) {
    const loggedInUser = useLoggedInUser();
    const [team, setTeam] = useState(null);
    const [dragTask, setDragTask] = useState(null);
    const { users } = useSystemUsers();

    useEffect(() => {
        if (loggedInUser?.id) {
            setTeam({
                id: Array.isArray(loggedInUser.planners) ? loggedInUser.planners[0]?.id : null,
                iAmLeader: true,
                name: Array.isArray(loggedInUser.planners) ? loggedInUser.planners[0]?.name : "Mój planer",
                members: [
                    {
                        ...loggedInUser,
                        name: loggedInUser?.fullName,
                        personId: loggedInUser?.id,
                        teamMemberId: Array.isArray(loggedInUser.planners) ? loggedInUser.planners[0]?.teamMemberId : null,
                        // teamId: Array.isArray(loggedInUser.planners) ? loggedInUser.planners[0]?.id : null,
                        isLeader: true
                    }
                ]
            })
        }
        else {
            setTeam(null);
        }
    }, [loggedInUser]);


    const goToPlannerButton = <a className="button show-all-planners" onClick={_ => window.open('/planer', '_self')}><div className="label">Przejdź do Planera</div><div className="icon"><img src="/ui/icons/right-arrow.svg" alt=""/></div></a>;

    return (
        <PlannerContext.Provider value={{dragTask, setDragTask, users}}>
            <div className='team-planners'>
                { team ?
                    <BoxTeamPlanner team={team} title="Mój planer" actions={goToPlannerButton}></BoxTeamPlanner>
                    : ''
                }
                <ToastContainer
                    position="bottom-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick={false}
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                    transition={Bounce}/>
                <Tooltip id="team-name-tooltip" style={{zIndex: 10}}/>
                <Tooltip id="team-tooltip-light" style={{zIndex: 10}}/>
            </div>
        </PlannerContext.Provider>
    )




    // return <BoxWrapper
    //     title="Mój Planer"
    //     icon="calendar"
    //     cta="Przejdź do Planera"
    //     action="/planer"
    //     className={"planner"+(expanded ? " expanded":'')}
    //     children=""
    //     collapseOnMobile={true}
    //     onExpandToggle={onExpandToggle}
    //     loaderCondition={ () => content === null}
    // >
    //     <div dangerouslySetInnerHTML={content}></div>
    // </BoxWrapper>
}
