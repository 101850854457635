import React, {useContext, useEffect, useRef, useState} from "react";
import BoxWrapper from "./BoxWrapper";
import useCalendarUI from "../hooks/calendar-ui";
import TeamTask from "./TeamTask";
import PlannerCell from "./PlannerCell";
import {SharedContext} from "../index";
import TeamTaskForm from "./TeamTaskForm";
import {PlannerContext} from "./TeamPlanners";
import {nthDayAfterDate, formatDateTime, getIsoDate, isWeekend, dayDiff, countWorkingDays} from "../lib/DateTools";
import CalendarEventsGrid, {countSpan} from "../lib/CalendarEventsGrid";
import {toast} from "react-toastify";
import Select from "react-select";
import useLoggedInUser from "../hooks/useLoggedInUser";
import useTeamTasks from "../hooks/useTeamTasks";

export default function BoxTeamPlanner({team: persistedTeam, title, actions}) {

    const plannerRef = useRef();
    const {
        actionsWidget,
        rowStyle,
        mode,
        monthNumber,
        monthName,
        numberOfDays,
        dateRange,
        prevPeriod,
        nextPeriod,
        formatDay,
        filters
    } = useCalendarUI(plannerRef);

    const [dateRangeISO, setDateRangeISO] = useState({start: '', end: ''});
    const [newTaskStartDate, setNewTaskStartDate] = useState();
    const [newTaskEndDate, setNewTaskEndDate] = useState();
    const [newTaksMember, setNewTaskMember] = useState(null);
    const {setModal, closeModal} = useContext(SharedContext);
    const {dragTask, setDragTask, users: systemUsers} = useContext(PlannerContext);
    const [isConfigVisible, setIsConfigVisible] = useState(false);
    const today = getIsoDate(new Date());
    const [team, setTeam] = useState({...persistedTeam});

    const {taskGrid, globalEvents, loadCalendar, loading} = useTeamTasks(team);
    const loggedInUser = useLoggedInUser();

    useEffect(() => {
        setDateRangeISO({
            start: getIsoDate(dateRange.start),
            end: getIsoDate(dateRange.end)
        })
    }, [dateRange]);

    useEffect(() => {
        loadCalendar(dateRangeISO.start, dateRangeISO.end)
    }, [dateRangeISO, team]);

    useEffect(() => {
        document.addEventListener('TeamTaskFormClose', cleanup);
        document.addEventListener('TeamTaskUpdate', onTeamTaskUpdate);
        return () => {
            document.removeEventListener('TeamTaskFormClose', cleanup);
            document.removeEventListener('TeamTaskUpdate', onTeamTaskUpdate);
        };
    }, []);


    function teamIncludesMemberId(teamMemberId) {
        if (teamMemberId === undefined) return false;
        return team.members.filter(tm => tm.teamMemberId === teamMemberId).length;
    }

    function teamIncludesPersonId(personId) {
        if (personId === undefined) return false;
        return team.members.filter(tm => tm.personId === personId).length;
    }

    function getIsoRange() {
        return new Promise((resolve, reject) => {
            let rangeStart, rangeEnd;
            setDateRangeISO(range => {
                resolve([range.start, range.end]);
                return range;
            });
        })
    }

    function onTeamTaskUpdate(e) {
        const {task, responseData, originalTask} = e.detail;
        getIsoRange().then(([start, end]) => {
            if (getIsoDate(task.startDate) <= end && getIsoDate(task.endDate) >= start || getIsoDate(originalTask?.startDate) <= end && getIsoDate(originalTask?.endDate) >= start) {
                if (team.id === task.teamId
                    || originalTask?.teamId === team.id
                    || teamIncludesMemberId(task.teamMemberId)
                    || task?.teamId !== originalTask?.teamId && teamIncludesMemberId(originalTask?.teamMemberId)
                    || teamIncludesPersonId(task?.personId)
                    || task?.personId !== originalTask?.personId && teamIncludesPersonId(originalTask?.personId)
                ) {
                    loadCalendar(start, end);
                }
            }
        })
    }

    function dragStart(date, member, e) {
        e?.preventDefault();
        if (!team.iAmLeader && (loggedInUser?.id !== member?.personId)) return;
        setNewTaskStartDate(date);
        setNewTaskEndDate(date);
        setNewTaskMember(member);
    }

    function getDroppedTask() {
        return new Promise((resolve, reject) => {
            setDragTask(dt => {
                if (dt?.task) {
                    resolve({...dt});
                } else reject();
            });
        })
    }

    function dragEnd(date, member, e) {
        if (newTaskStartDate && member === newTaksMember && (team.iAmLeader || loggedInUser?.id === member?.personId)) {
            showNewTaskForm(newTaskStartDate, date, newTaksMember);
        } else {
            getDroppedTask().then((taskWrapper) => {
                /** Resize is processed by useTaskResize.js/resizeEnd() */
                if (taskWrapper.mode === 'resize') return null;

                const originalTask = {...taskWrapper.task}
                const droppedTask = {...originalTask};

                if (taskWrapper.sourceVisibleTeamMember === member?.teamMemberId) {
                    // Don't change team if task is moved within team member's row even if it's task from another team
                }
                else {
                    droppedTask.teamMemberId = member?.teamMemberId;
                    droppedTask.teamId = member.teamId || team.id;
                }

                let droppedTaskLength = (new Date(droppedTask.endDate)).getTime() - (new Date(droppedTask.startDate)).getTime();

                droppedTask.startDate = formatDateTime(nthDayAfterDate(date, -taskWrapper.grabbedDayIndex || 0), 8);
                droppedTask.endDate = formatDateTime((new Date(droppedTask.startDate)).getTime() + droppedTaskLength, 18);

                const direction = dayDiff(originalTask.startDate, droppedTask.startDate);
                const workingDays = countWorkingDays(originalTask.startDate, originalTask.endDate);

                if (direction < 0) {
                    while (isWeekend(droppedTask.startDate)) {
                        droppedTask.startDate = formatDateTime(nthDayAfterDate(droppedTask.startDate, -1), 8);
                    }
                    while (isWeekend(droppedTask.endDate)) {
                        droppedTask.endDate = formatDateTime(nthDayAfterDate(droppedTask.endDate, -1), 18);
                    }
                }
                if (direction > 0) {
                    while (isWeekend(droppedTask.startDate)) {
                        droppedTask.startDate = formatDateTime(nthDayAfterDate(droppedTask.startDate, 1), 8);
                    }
                    while (isWeekend(droppedTask.endDate)) {
                        droppedTask.endDate = formatDateTime(nthDayAfterDate(droppedTask.endDate, 1), 18);
                    }
                }

                const workingDaysDifference = workingDays - countWorkingDays(droppedTask.startDate, droppedTask.endDate);
                if (workingDaysDifference !== 0) {
                    droppedTask.endDate = formatDateTime(nthDayAfterDate(droppedTask.endDate, workingDaysDifference), 18);
                    while (isWeekend(droppedTask.endDate)) {
                        droppedTask.endDate = formatDateTime(nthDayAfterDate(droppedTask.endDate, -1), 18);
                    }
                }

                const method = e.altKey ? 'POST' : 'PUT';

                let url = '/api/team/tasks';
                if (e.altKey) delete droppedTask.id;
                else url += `/${droppedTask.id}`;

                fetch(url, {method, body: JSON.stringify(droppedTask)}).then(response => {
                    if (response.ok) response.json().then(
                        data => {
                            if (data.status === 'ok') {
                                const e = new CustomEvent('TeamTaskUpdate', {
                                    detail: {
                                        task: droppedTask,
                                        responseData: data,
                                        originalTask: originalTask
                                    }
                                });
                                document.dispatchEvent(e);
                            }
                            else {
                                if (data.status === 'error') toast.error(data.message);
                            }
                        }
                    )
                });
                return null;
            }, () => {
                setNewTaskMember(null);
            });
        }
    }

    function dragOver(date, member) {
        if (newTaskStartDate && member === newTaksMember) {
            setNewTaskEndDate(date);
        }
    }

    function cleanup() {
        setNewTaskMember(null);
        setNewTaskStartDate(null);
        setNewTaskEndDate(null);
    }

    function cleanupAndCloseModal() {
        cleanup();
        closeModal();
    }

    function tempTask() {
        const tempTask = {
            id: -1,
            first: true,
            description: 'nowe zadanie',
            color: 0,
            startDate: newTaskStartDate,
            endDate: newTaskEndDate,
            teamMemberId: newTaksMember.id
        }
        tempTask.span = countSpan(tempTask);
        return tempTask;
    }

    function showNewTaskForm(startDate, endDate, teamMember) {
        setModal(<TeamTaskForm startDate={startDate} endDate={endDate} close={cleanupAndCloseModal}
                               teamMember={teamMember} team={team}
        />);
    }

    function userToOption(user) {
        const userName = user.firstName + ' ' + user.lastName;
        return {
            value: user.id,
            label: userName
        }
    }

    function isTeamMember(su) {
        return team.members.some(tm => tm.personId === su.id);
    }

    function changeMembers(options) {
        const personIds = options.map( o => o.value);
        const teamMembers = team.members.filter( tm => personIds.includes(tm.personId) );
        const teamMembersPersonIds = teamMembers.map( tm => tm.personId );
        const personIdsToAdd = personIds.filter( personId => !teamMembersPersonIds.includes(personId) );

        const temporaryTeamMembers = personIdsToAdd.map( personId => systemUsers.find( su => su.id === personId ))?.map(systemUser => {
            return {
                id: 'temporaryTeamMemberEntityId_'+systemUser.id,
                teamId: team.teamId,
                teamMemberId: 'temporaryMemberId_'+systemUser.id,
                personId: systemUser.id,
                name: `${systemUser.firstName} ${systemUser.lastName}`,
                email: systemUser.email,
                avatar: systemUser.avatar,
                isActive: true,
                isLeader: false
            }
        });

        setTeam( {...team, members: teamMembers.concat(temporaryTeamMembers)} );

    }

    function handleTeamConfigAbort() {
        setIsConfigVisible(false);
        setTeam({...persistedTeam});
    }

    async function handleTeamConfigSave() {
        try {
            const resp = await fetch(`/api/team/${team.id}`, {
                method: 'PUT',
                credentials: 'same-origin',
                body: JSON.stringify(
                    {
                        memberIds: team.members.map(member => member.personId)
                    }
                )
            });
            if (!resp.ok) throw Error('Nie udało się zapisać zmian w zespole. Błąd API.');
            const data = await resp.json();
            if (data.status !== 'ok') throw Error('Nie udało się zapisać zmian w zespole.');

            setIsConfigVisible(false);
            setTeam(data.team);
        }
        catch (e) {
            toast.error(e.message)
        }
    }

    const alteredActionsWidget = <>
        { team.allowQuickMods ?
            <a className="button" onClick={_ => setIsConfigVisible(v => !v)}><div className="label"><i className='fa fa-wrench'></i></div></a>
            : ''
        }
        { actionsWidget }
        { actions ? actions : '' }
    </>

    function renderPlannerCell(cellDate, member, dayTasks) {
        return <PlannerCell globalEvent={globalEvents[getIsoDate(cellDate)]}
                            date={cellDate}
                            isToday={getIsoDate(cellDate) === today}
                            teamMember={member}
                            dragStartHandle={(e) => dragStart(cellDate, member, e)}
                            dragEndHandle={(e) => dragEnd(cellDate, member, e)}
                            dragOverHandle={() => dragOver(cellDate, member)}
            // onClick={() => { showNewTaskForm(cellDate, cellDate, member);}}
        >
            {dayTasks.map(task =>
                <TeamTask shouldShowAcronyms={team.showAcronyms} task={task} team={team} teamMember={member} key={task.id}
                          filters={filters}
                ></TeamTask>
            )}
            {newTaksMember === member && getIsoDate(cellDate) === getIsoDate(newTaskStartDate) ? (
                <TeamTask className='temporary' temporary={true} team={team} task={tempTask()} teamMember={member}></TeamTask>
            ) : ''}
        </PlannerCell>
    }

    return <BoxWrapper data={{'team-id': team.id}} className='team' icon="calendar" title={title || team.name} actionsWidget={ actions || team.allowQuickMods ? alteredActionsWidget : actionsWidget }
                       ref={plannerRef} loaderCondition={_ => loading}
    >
        { isConfigVisible ? <div className="config">
            <div className="label">Członkowie zespołu</div>
            <div style={{flex: 1, color: 'black'}}>
                <Select
                    options={systemUsers?.map(userToOption)}
                    closeMenuOnSelect={false}
                    isMulti
                    onChange={ changeMembers }
                    isSearchable={true}
                    isClearable={false}
                    defaultValue={systemUsers?.filter(isTeamMember)?.map(userToOption)}

                    // inputValue={}
                    // value={}

                />
            </div>
            <a className='negative button' onClick={ handleTeamConfigAbort }>Anuluj</a>
            <a className='positive button' onClick={ handleTeamConfigSave }>Zapisz</a>
        </div> : '' }
        <div className='planner-header-row' style={rowStyle} data-mode={mode}>
            <div className="planner-month">
                <span className="month-number">{monthNumber}</span>
                <span className="month-name">{monthName}</span>
            </div>
            {[...Array(numberOfDays)].map((_, index) => {
                const date = nthDayAfterDate(dateRange.start, index);
                const dateString = getIsoDate(date);
                return <div
                    className={'planner-day' + (today === dateString ? ' today' : '') + (isWeekend(date) ? ' weekend' : '') + (globalEvents[dateString] ? ' global-event' : '')}
                    data-date={dateString} data-tooltip-id="team-tooltip-light" data-tooltip-content={globalEvents[dateString]?.description}
                >{formatDay(nthDayAfterDate(dateRange.start, index))}</div>
            })}
            <div className="prev" onClick={prevPeriod}><img src="/ui/icons-black/left-circle-arrow.svg" alt=""/></div>
            <div className="next" onClick={nextPeriod}><img src="/ui/icons-black/right-circle-arrow.svg" alt=""/></div>
        </div>


        <div className='planner-row unassigned-tasks' style={rowStyle}
             data-member-id={0}
             data-person-id={0}
        >
            <div className='planner-member'>
                <div className='name'>{team.name}</div>
            </div>
            {taskGrid[0]?.map(
                (dayTasks, dayIndex) => {
                    const cellDate = nthDayAfterDate(dateRange.start, dayIndex);
                    return renderPlannerCell(cellDate, 0, dayTasks);
                }
            )}
        </div>

        {team.members?.map(
            /** @param {TeamMemberInterface} member */
            (member, memberIndex) => (
                <div className={'planner-row' + (loggedInUser?.id === member.personId ? ' personal-planner':'')}  style={rowStyle}
                     data-member-id={member.teamMemberId}
                     data-person-id={member.personId}
                >
                    <div className='planner-member'>
                        <img className='avatar' src={member.avatar} alt={member.name}/>
                        <div className='name'>{member.name} { member.isLeader ? <i className='fa fa-check'></i> : '' }</div>
                    </div>
                    {taskGrid[memberIndex + 1]?.map(
                        (dayTasks, dayIndex) => {
                            const cellDate = nthDayAfterDate(dateRange.start, dayIndex);
                            return renderPlannerCell(cellDate, member, dayTasks);
                        }
                    )}
                </div>
            )
        )}
    </BoxWrapper>
}

