
function formatDateTime(date, hours) {
    const d = new Date(date);
    if (hours !== undefined) {
        d.setHours(hours, -d.getTimezoneOffset(), 0);
    }
    return d.toISOString().replace(/\..*/, '').replace('T', ' ')
}

function getIsoDate(date) {
    return (new Date(date)).toISOString().replace(/T.*/, '');
}

function increaseMonths(date, incrementor) {
    const newDate = new Date(date);
    let years = date.getFullYear();
    let months = date.getMonth() + incrementor;
    const yearInc = Math.floor(months / 12);
    months = months % 12;
    if (months < 0) {
        months = 12 + months;
    }
    years += yearInc;

    newDate.setFullYear(years);
    newDate.setMonth(months);
    return newDate;
}

function isWeekend(date) {
    date = new Date(date);
    return date.getDay() === 0 || date.getDay() === 6;
}

function dayDiff(date1, date2) {
    return ((new Date(date2)).getTime() - (new Date(date1)).getTime()) / 1000 / 60 / 60 / 24;
}

function countWorkingDays(start, end) {
    let workingDays = 0;
    let date = new Date(start);
    const endDate = new Date(end);
    while (date < endDate) {
        workingDays += !isWeekend(date) ? 1 : 0;
        date = nthDayAfterDate(date, 1)
    }
    return workingDays
}

function nthDayAfterDate(base, nth) {
    let d = new Date(base);
    d.setDate(d.getDate() + nth);
    return d;
}

/**
 *
 * @param {Date | string} date
 * @return {Date}
 */
function getMonday(date) {
    let d = new Date(date); // Clone the date to avoid mutation
    let day = d.getDay(); // Get the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
    let diff = day === 0 ? -6 : 1 - day; // Adjust for Sunday being 0
    d.setDate(d.getDate() + diff);
    return d;
}

function getMonthLength(date) {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    return new Date(year, month, 0).getDate();
}

function timeDifferenceInMinutes(time1, time2) {
    return ((new Date(time2)).getTime() - (new Date(time1)).getTime() ) / 1000 / 60;
}


export {formatDateTime, getIsoDate, increaseMonths, isWeekend, dayDiff, nthDayAfterDate, countWorkingDays, getMonday, getMonthLength, timeDifferenceInMinutes}
