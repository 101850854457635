import {useContext, useEffect, useRef, useState} from "react";
import {PlannerContext} from "../components/TeamPlanners";
import {dayDiff, nthDayAfterDate, formatDateTime, getIsoDate, isWeekend} from "../lib/DateTools";

/**
 * 
 * @param {TeamTaskInterface} task
 * @param ref
 * @return {{endDrag: endDrag, startDrag: startDrag}}
 */
export default function useTaskResize(task, ref) {
    const dummy = useRef();
    const [isDrag, setIsDrag] = useState(false);
    const initialPosition = useRef({startX: null, endX: null, initialMouseX: null, handleSide: null, cellWidth: null});
    const plannerContext = useContext(PlannerContext);

    useEffect( () => {
        if (ref.current) {
            if (isDrag) {
                ref.current.classList.add('frozen')
            } else {
                ref.current.classList.remove('frozen')
            }
        }
        return () => {
            if (!ref.current) return;
            ref.current.classList.remove('frozen')
        }
    }, [isDrag]);

    useEffect(() => detachEvents, []);

    /** @param {MouseEvent} e */
    function startResize(e) {
        e.stopPropagation();
        e.preventDefault();
        document.addEventListener('mouseup', resizeEnd);
        setIsDrag(true);
        initialPosition.current = {
            startX: ref.current.offsetLeft,
            endX: ref.current.offsetLeft + ref.current.offsetWidth,
            initialMouseX: e.clientX,
            handleSide: e.target.dataset.side,
            cellWidth: ref.current.closest('.planner-cell')?.offsetWidth
        };
        dummy.current = createDummyTask(ref.current, ref.current.offsetLeft, ref.current.offsetTop);
        if (!dummy.current) return;

        plannerContext.setDragTask({
            node: ref.current,
            task,
            mode: 'resize',
            resize: e.target.dataset.side
        });
        document.addEventListener('mousemove', resizeTask);
        document.addEventListener('keyup', dragTerminate);
    }

    function endDrag(e) {
        if (isDrag) e.stopPropagation()
    }

    function createDummyTask(sourceElement, x, y) {
        const dummy = sourceElement?.cloneNode(true);
        if (!dummy) return;
        dummy.classList.add('dummy');
        dummy.classList.add('resize');
        dummy.style.width = sourceElement.offsetWidth + 'px';
        dummy.style.height = sourceElement.offsetHeight + 'px';
        // dummy.style.transform = `translate(-${offsetX}px, -${offsetY}px)`;
        dummy.style.left = x + 'px';
        dummy.style.top = y + 'px';
        sourceElement.parentElement.appendChild(dummy);
        return dummy;
    }

    function resizeTask(e) {
        if (!dummy.current) return;
        const x = e.clientX;
        const dist = x - initialPosition.current.initialMouseX;
        const isEndPoint = initialPosition.current.handleSide === 'end';

        // let dist = isEndPoint ? x - endX : x - startX;

        const left = isEndPoint ? Math.min(initialPosition.current.endX + dist, initialPosition.current.startX) : Math.min(initialPosition.current.startX + dist,  initialPosition.current.endX);
        const right = isEndPoint ? Math.max(initialPosition.current.endX + dist, initialPosition.current.startX) : Math.max(initialPosition.current.startX + dist,  initialPosition.current.endX);

        const position = left;
        const width = right - left;

        dummy.current.style.left = position + 'px';
        dummy.current.style.width = width + 'px'
    }

    function resizeEnd(e) {

        const leftMargin = parseInt(window.getComputedStyle(ref.current).getPropertyValue('--task-margin-left'));
        const rightMargin = parseInt(window.getComputedStyle(ref.current).getPropertyValue('--task-margin-right'));
        
        let modifiedTask = {...task};

        if (initialPosition.current.handleSide === 'start') {
            const distance = e.pageX - (initialPosition.current.initialMouseX - leftMargin);
            const startDateIncrement = Math.floor(distance / initialPosition.current.cellWidth);
            modifiedTask.startDate = formatDateTime(nthDayAfterDate(task.startDate, startDateIncrement), 8);
            if (startDateIncrement < 0) {
                while (isWeekend(modifiedTask.startDate)) {
                    modifiedTask.startDate = formatDateTime(nthDayAfterDate(modifiedTask.startDate, -1), 8);
                }
            }
            else if (startDateIncrement > 0) {
                while (isWeekend(modifiedTask.startDate)) {
                    modifiedTask.startDate = formatDateTime(nthDayAfterDate(modifiedTask.startDate, 1), 8);
                }
            }

        }
        if (initialPosition.current.handleSide === 'end') {
            const distance = e.pageX - (initialPosition.current.initialMouseX + rightMargin);
            const endDateIncrement = Math.ceil(distance / initialPosition.current.cellWidth);
            modifiedTask.endDate = formatDateTime(nthDayAfterDate(task.endDate, endDateIncrement), 18);
            if (endDateIncrement > 0) {
                while (isWeekend(modifiedTask.endDate)) {
                    modifiedTask.endDate = formatDateTime(nthDayAfterDate(modifiedTask.endDate, 1), 18);
                }
            }
            else if (endDateIncrement < 0) {
                while (isWeekend(modifiedTask.endDate)) {
                    modifiedTask.endDate = formatDateTime(nthDayAfterDate(modifiedTask.endDate, -1), 18);
                }
            }
        }

        if (modifiedTask.endDate < modifiedTask.startDate) {
            const startDate = modifiedTask.startDate;
            modifiedTask.startDate = formatDateTime(modifiedTask.endDate, 8);
            modifiedTask.endDate = formatDateTime(startDate, 18);
        }

        let url = `/api/team/tasks/${task.id}`;

        fetch(url, {method: 'PUT', body: JSON.stringify(modifiedTask)}).then( response => {
            if (response.ok) response.json().then(
                data => {
                    if (data.status === 'ok') {
                        const e = new CustomEvent('TeamTaskUpdate', {detail: {task: modifiedTask, responseData: data, originalTask: task}});
                        document.dispatchEvent(e);
                    }
                }
            )
        });

        e.stopPropagation();
        setIsDrag(false);
        detachEvents();
        if (!dummy.current) return;
        dummy.current.parentElement.removeChild(dummy.current);
        dummy.current = null;
    }

    function dragTerminate(e) {
        if (!dummy.current) return;
        if (e.key === 'Escape') {
            dummy.current.parentElement.removeChild(dummy.current);
            dummy.current = null;
            setIsDrag(false);
            plannerContext.setDragTask(null);
            detachEvents();
        }
    }

    function detachEvents() {
        document.removeEventListener('mousemove', resizeTask);
        document.removeEventListener('mouseup', resizeEnd);
    }

    return {startResize, endDrag}

}



