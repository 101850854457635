import {AppContext} from "../index";
import {useEffect, useState} from "react";
export default function useLoggedInUser() {
    const [user, setUser] = useState({});
    const appContext = AppContext.getInstance();

    useEffect(() => {
        if (appContext.value?.loggedInUser) {
            setUserFromContext();
        }
        else {
            document.addEventListener('loggedInUserDataLoaded', setUserFromContext )
        }
        return () => {
            document.removeEventListener('loggedInUserDataLoaded', setUserFromContext )
        };
    }, []);
    
    function setUserFromContext() {
        setUser(appContext.value.loggedInUser);
    }

    return user;
}
