import "./AssignmentStatusPopup.scss";
import "./ModalPopup.scss";
import AppContext from "../AppContext";
import TitleWithIcon from "./TitleWithIcon";
import React from "react";

export default function AssignmentStatusPopup({assignment, onSelect: handleSelect, assignmentType}) {
    const appContext = AppContext.getInstance();
    return (
        <div className="modal-popup assignment_status_popup">
            <TitleWithIcon title={assignmentType==="publishing" ? "Status zlecenia" : "Status projektu"} icon={assignmentType==="publishing" ? "assignment" : "design"}/>
            <div className="content container">
                { appContext.value.statuses.map(
                    status => status.slug!=='przetarg-przegrany' || assignmentType==="design" ? (
                        <div className={assignment.status === status.slug ? 'active row' : 'row'} onClick={() => handleSelect(assignment.id, status.slug)}>
                            <div className="dot" style={{background: status.color}}></div>
                            {status.name}
                        </div>
                    ) : ''
                )}
            </div>
        </div>
    )
}
