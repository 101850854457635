import React, {useContext, useEffect, useRef, useState} from "react";
import {SharedContext} from "../index";
import TeamTaskForm from "./TeamTaskForm";
import {PlannerContext} from "./TeamPlanners";
import classNames from "classnames";
import useTaskDrag from "../hooks/useTaskDrag";
import parse from 'html-react-parser';
import useTaskResize from "../hooks/useTaskResize";
import useLoggedInUser from "../hooks/useLoggedInUser";

/**
 *
 * @param {TeamTaskInterface|AbsenceInterface} task
 * @param {TeamMemberInterface} teamMember
 * @return {JSX.Element}
 * @constructor
 */
export default function TeamTask({task, team, teamMember, className, filters, shouldShowAcronyms}) {
    const ref = useRef();
    const [textContent, setTextContent] = useState('')
    const [title, setTitle] = useState('');
    const {setModal, closeModal} = useContext(SharedContext);
    const { startDrag, endDrag } = useTaskDrag(task, teamMember, ref);
    const { startResize, endDrag: endDragOnTask } = useTaskResize(task, ref);
    const isAbsenceEvent = 'type' in task;
    const loggedInUser = useLoggedInUser();

    function showNewTaskForm(e) {
        e.preventDefault();
        if (!team.iAmLeader && teamMember.personId !== loggedInUser.id) return;
        setModal(<TeamTaskForm close={closeModal} teamMember={teamMember} task={task} team={team}/>);
    }

    useEffect(() => {
        if (task.id && task.first) {
            const taskElement = ref.current;
            if (!taskElement) return;

            let width = task.span * 100 + '%';
            taskElement.style.width=`calc(${width} - var(--task-margin-left) - var(--task-margin-right))`;

            parseAndUpdateTextContent(task);
        }
        else {
            setTextContent('');
        }
        return () => {
            if (!ref.current) return;
            ref.current.style.width = 'auto';
            setTextContent('');
            setTitle('')
        };

    }, [task]);

    useEffect(() => {
        document.addEventListener('TeamTaskUpdate', onTeamTaskUpdate);
        return () => {
            document.removeEventListener('TeamTaskUpdate', onTeamTaskUpdate);
        }
    }, []);

    function onTeamTaskUpdate(e) {
        const {task: updatedTask, responseData} = e.detail;
        if (task.id === updatedTask.id) {
            Object.assign(task, updatedTask);
            parseAndUpdateTextContent(task);
            // const e = new CustomEvent('taskUpdate');
            // e.bubbles = true;
            // ref.current?.dispatchEvent(e);
        }
    }

    function parseAndUpdateTextContent(task) {
        if (task.type !== 'meetings') {
            let description = `<p>${task.description}</p>`;
            description = description.replace(/@d(\d+)/g, '</p><a href="/design/$1/show" class="designLink">d$1</a><p>');
            description = description.replace(/@p(\d+)/g, '</p><a href="/zlecenie/$1" class="publishingLink">p$1</a><p>');
            description = description.replace(/^<\/p>/, '').replace(/<p>$/, '');

            if (!isAbsenceEvent) {
                if (task.initial) {
                    setTextContent(`<em class="otherTeam" data-tooltip-id="team-name-tooltip" data-tooltip-content="${task.teamName}"></em>${description}`);
                    setTitle(`[${task.teamName}] ${task.description} // ${task.createdBy?.name} @ ${task.createdAt}`)
                }
                else {
                    if (shouldShowAcronyms) {
                        let acronym = task.createdBy?.name.replace(/[^\w&+_\-]/g,' ').split(/ +/).map(word => word.substring(0,1)).join('');
                        setTextContent(`<em class="ownerAcronym" data-tooltip-id="team-name-tooltip" data-tooltip-content="${task.createdBy?.name}">${acronym}</em>${description}`);
                    }
                    else {
                        setTextContent(`${description}`);
                    }
                    setTitle(`${task.description} // ${task.createdBy?.name} @ ${task.createdAt}`)
                }
            }
            else {
                setTextContent(`${description}`);
                setTitle(`${task.description} // system @ ${task.createdAt}`)
            }
        }
        else {
            const meetings = task.meetingsList.map( meeting => `<div class="tasks_from_system" title="${meeting.description} // system @ ${meeting.createdAt}"><p>${meeting.description}</p></div>`)
            const width = task.duration / (8*60) * 100;
            setTextContent(`<div class="task-progress" style="width: ${width}%"></div><p>${task.description}</p><div class="tasks_popup">${meetings.join('')}</div>`);
        }

    }

    function checkFilter(color) {
        if (!filters) return true;
        switch (parseInt(color)) {
            case 0:
            case 1:
            case 2:
                return filters.biezace
            case 3:
            case 4:
            case 5:
                return filters.wewnetrzne
            case 6:
            case 7:
            case 8:
                return filters.przetargi
            case 9:
            case 10:
                return filters.kalendarz
            case 11:
                return filters.spotkania
        }
        return true;
    }

    const divClasses = classNames(className??'', 'task', {
        'first': task.first,
        'spacer': !task.first,
        'continues': task.continues,
        'continued': task.continued,
        'fromAnotherTeam': !!task.initial,
        'invisible': !isAbsenceEvent ? !checkFilter(task.color) : (task.type === 'meetings' ? !filters.spotkania : !filters.kalendarz),
        'squashed': task.initial && !filters.inne
    })

    const taksColor = !isAbsenceEvent ? task.color : (task.type.match(/meetings?/) ? 11 : 10);

    return <div className={divClasses}
                ref={ref} title={title}
                data-color={taksColor}
                onMouseDown={!isAbsenceEvent ? startDrag : e => e.stopPropagation() }
                onMouseUp={ endDrag }
                onClick={ !isAbsenceEvent ? (e => 'A' === e.target?.nodeName ? void(null) : showNewTaskForm(e)) : e => e.stopPropagation() }>
        { parse(textContent) }
        { (!isAbsenceEvent && task.first && !task.continued) ? <div className="taskLeftHandle" data-side="start" onMouseDown={ startResize } onMouseUp={ endDragOnTask }></div> : '' }
        { (!isAbsenceEvent && task.first && !task.continues) ? <div className="taskRightHandle" data-side="end" onMouseDown={ startResize } onMouseUp={ endDragOnTask }></div> : '' }
    </div>
}
