import React, {useEffect, useMemo, useRef, useState} from "react";
import ConfigToggleBar from "./ConfigToggleBar";
import "./DashboardEdit.scss";
import "./ModalPopup.scss";
import {usePositionReorder} from "../hooks/use-position-reorder";
import TitleWithIcon from "./TitleWithIcon";


export default function TeamsViewEdit({onChanges: propagateChanges, config, close}) {

    const teamsList = config;

    let [teamsViewConfig, setTeamsViewConfig] = useState(teamsList);
    const initialConfig = useMemo(() => config.map(row => Object.assign({}, row)), []);
    const constraintsRef = useRef(null)

    function applyChanges(modules, layout) {
        setTeamsViewConfig(modules);
        propagateChanges(modules);
    }

    const [updatedList, updatePosition, updateOrder] = usePositionReorder(teamsList, applyChanges);

    function toggle (team, index) {
        team.checked = ! team.checked;
        const newTeams = [...teamsViewConfig];
        newTeams.splice(index, 1, team);
        setTeamsViewConfig(newTeams);
        propagateChanges(newTeams)
    }
    function handleEscape(e) {
        if (e.key === "Escape") {
            applyChanges(initialConfig);
        };
    }

    useEffect( () => {
        document.addEventListener('keydown', handleEscape);
        return () => {
            document.removeEventListener('keydown', handleEscape);
        }
    }, []);

    return <div className="modal-popup dashboardEditWrapper">
        <TitleWithIcon title="Teams" icon="dash"/>
        <div className="description">
            Zaznacz wybrany team w celu dodania go do poniższego widoku. Możesz zmienić kolejność teamów przeciągając je przy pomocy przycisku <img src="/ui/icons-black/handle.svg" style={{verticalAlign: "middle", margin: "0 0.5em"}} alt=""/>
        </div>
        <div className="content container" ref={constraintsRef}>
            {
                updatedList.map(
                    (team, index) => {
                        return <ConfigToggleBar
                            title={team.name}
                            checked={team.checked}
                            onToggle={() => toggle(team, index)}
                            key={team.id}
                            ind={index}
                            updateOrder={updateOrder}
                            updatePosition={updatePosition}
                            constraintsRef={constraintsRef}
                        />
                    }
                )
            }
        </div>
        <div className="footer">
            <div className="button" onClick={close}>
                ZAPISZ ZMIANY
            </div>
        </div>
    </div>
}
