import CalendarEventsGrid from "../lib/CalendarEventsGrid";
import {toast} from "react-toastify";
import {useState} from "react";

export default function useTeamTasks(team) {
    const [taskGrid, setTaskGrid] = useState([]);
    const [globalEvents, setGlobalEvents] = useState({});
    const [loading, setLoading] = useState(false);
    async function loadCalendar(start, end) {
        if (!start || !end) return;
        setLoading(true);

        try {
            const resp = await fetch(`/api/team/${team.id}/tasks?from=${start}&to=${end}`);
            if (!resp.ok) { throw new Error(`HTTP error! Status: ${resp.status}`); }

            const teamMembersPersonalIds = team.members.map ( tm => tm.personId ).join(',');
            const respAbsences = await fetch(`/api/absence?of=${teamMembersPersonalIds}&from=${start}&to=${end}`);
            if (!respAbsences.ok) { throw new Error(`HTTP error! Status: ${resp.status}`); }

            const respOthers = await fetch(`/api/team/tasks?of=${teamMembersPersonalIds}&from=${start}&to=${end}`);
            if (!respAbsences.ok) { throw new Error(`HTTP error! Status: ${resp.status}`); }

            // const respOthers = await fetch(`/api/team/${team.id}/others/tasks?from=${start}&to=${end}`);
            // if (!respAbsences.ok) { throw new Error(`HTTP error! Status: ${resp.status}`); }

            const data = await resp.json();
            const absenceData = await respAbsences.json();
            const othersData = await respOthers.json();

            if (data.status !== 'ok') {
                throw new Error(`API error! Status: ${data.message}`);
            }

            let grid = new CalendarEventsGrid(start, end, team.members);
            grid.parseTasks(data.tasks, absenceData.absences, othersData.tasks.filter(t => t.teamId !== team.id));
            grid.fillNullTaskSlots();

            setTaskGrid(grid.getGrid());
            setGlobalEvents(grid.globalEvents);

        } catch (e) {
            toast.error(e.message)
        } finally {
            setLoading(false);
        }
    }
    return {loadCalendar, taskGrid, globalEvents, loading}
}

