import React, {useEffect, useState} from "react";
import {nthDayAfterDate, increaseMonths, getMonthLength, getMonday} from "../lib/DateTools";

const WEEK_DAYS = ['Nd', 'Pn', 'Wt', 'Śr', 'Cz', 'Pt', 'So'];
const MONTH_NAMES = ['Styczeń', 'Luty', 'Marzec', 'Kwiecień', 'Maj', 'Czerwiec', 'Lipiec', 'Sierpień', 'Wrzesień', 'Październik', 'Listopad', 'Grudzień'];

export default function useCalendarUI(uiElement) {

    const [mode, setMode] = useState('week');
    const [numberOfDays, setNumberOfDays] = useState(5);
    const today = new Date();
    const [dateRange, setDateRange] = useState({start: getMonday(today), end: nthDayAfterDate(getMonday(today), 4)});
    const [rowStyle, setRowStyle] = useState({})
    const [monthName, setMonthName] = useState('');
    const [monthNumber, setMonthNumber] = useState('');
    const [actionsWidget, setActionsWidget] = useState(<></>);
    const [fullscreen, setFullscreen] = useState(false);
    const [filters, setFilters] = useState({
        biezace: true,
        wewnetrzne: true,
        przetargi: true,
        kalendarz: true,
        spotkania: true,
        inne: true
    });

    function toggleMode() {
        setMode(currentMode => currentMode === 'week' ? 'month' : 'week');
    }

    function goToToday() {
        const today = new Date();
        setDateRangeFrom(today, mode);
    }

    function toggleFilter(filterName) {
        setFilters( currentFilters => {
            const newFilters = {...currentFilters};
            newFilters[filterName] = !newFilters[filterName];
            return newFilters;
        })
    }

    useEffect(() => {
        setDateRangeFrom(dateRange.start, mode)
    }, [mode]);

    useEffect(() => {
        setActionsWidget(<>
            <div className="calendar-filters">
                <div className="filter" style={{background: '#C4E084'}} title="Bieżące" onClick={() => toggleFilter('biezace')}>
                    <input type="checkbox" data-for="current" checked={filters.biezace} />
                </div>
                <div className="filter" style={{background: '#85B7E0'}} title="Wewnętrzne" onClick={() => toggleFilter('wewnetrzne')}>
                    <input type="checkbox" data-for="internal" checked={filters.wewnetrzne} />
                </div>
                <div className="filter" style={{background: '#F0A8C0'}} title="Przetargi" onClick={() => toggleFilter('przetargi')}>
                    <input type="checkbox" data-for="tender" checked={filters.przetargi} />
                </div>
                <div className="filter" style={{background: '#B8B8B8'}} title="Kalendarz" onClick={() => toggleFilter('kalendarz')}>
                    <input type="checkbox" data-for="calendar" checked={filters.kalendarz}/>
                </div>
                <div className="filter" style={{background: '#ffffff'}} title="Spotkania" onClick={() => toggleFilter('spotkania')}>
                    <input type="checkbox" data-for="meetings" checked={filters.spotkania}/>
                </div>
                <div className="filter" style={{background: '#ffffff'}} title="Wszystkie kalendarze" onClick={() => toggleFilter('inne')}>
                    <input type="checkbox" data-for="other-teams" checked={filters.inne}/>
                    <i className="fa fa-list" style={{color: 'black', fontSize: '14px', marginLeft: '5px', marginRight: '3px'}}></i>
                </div>
            </div>
            <a className="button" onClick={goToToday}><div className="label">Dziś</div></a>
            <a className="button" onClick={toggleMode}><div className="label">{mode === 'week' ? 'Widok miesiąca' : 'Widok tygodnia'}</div></a>
            { 'requestFullscreen' in document.createElement('div') ?
                <a className="button full-screen-toggle" onClick={toggleFullScreen} title={fullscreen ? "Wyjdź z trybu pełnoekranowego" : "Powiększ na pełny ekran"}>
                    <div className="label"><i className={"fa " + (fullscreen ? 'fa-compress' : 'fa-expand')}></i></div>
                </a>
                :
                ''
            }

        </>);
    }, [mode, fullscreen, filters]);
    

    useEffect(() => {
        setRowStyle({gridTemplateColumns: `var(--planner--first-column-width, 200px) repeat(${numberOfDays}, minmax(0, 1fr))`})
    }, [numberOfDays]);

    useEffect(() => {
        const firstMonth = dateRange.start.getMonth();
        const lastMonth = nthDayAfterDate(dateRange.start, numberOfDays-1).getMonth();
        if (firstMonth === lastMonth) {
            setMonthName(MONTH_NAMES[firstMonth]);
            setMonthNumber(('0'+(firstMonth+1)).substr(-2) + '.');
        }
        else {
            setMonthName(MONTH_NAMES[firstMonth] + ' / ' + MONTH_NAMES[lastMonth]);
            setMonthNumber(('0'+(firstMonth+1)).substr(-2) + '. / ' + ('0'+(lastMonth+1)).substr(-2) + '.');
        }
    }, [dateRange]);

    useEffect(() => {
        if (fullscreen) {
            uiElement.current?.requestFullscreen();
            if (uiElement.current) { uiElement.current.style.overflow = 'auto' }
        }
        else {
            if (document?.fullscreenElement) document.exitFullscreen();
        }
        return () => {
            if (document?.fullscreenElement) document.exitFullscreen();
        };
    }, [fullscreen]);

    useEffect(() => {
        document.addEventListener("fullscreenchange", fullscreenchangeHandler);
        return () => {
            document.removeEventListener("fullscreenchange", fullscreenchangeHandler);
        };
    }, []);

    function fullscreenchangeHandler(e) {
        if (!document.fullscreenElement) {
            uiElement.current.style.overflow = null;
        }
    }

    

    function setDateRangeFrom(firstDay, mode) {
        if (mode === 'week') {
            const monday = getMonday(firstDay);
            setDateRange({start: monday, end: nthDayAfterDate(monday, 4)});
            setNumberOfDays(5);
        }
        else if (mode === 'month') {
            const firstDayOfTheMonth = new Date(firstDay);
            firstDayOfTheMonth.setDate(1);
            const monthLength = getMonthLength(firstDayOfTheMonth);
            const lastDayOfTheMonth = nthDayAfterDate(firstDayOfTheMonth, monthLength - 1);
            setDateRange({
                start: firstDayOfTheMonth,
                end: lastDayOfTheMonth
            }) ;
            setNumberOfDays(getMonthLength(firstDay));
        }
    }
    function prevPeriod() {
        if (mode === 'week'){
            setDateRange({
                start: nthDayAfterDate(dateRange.start, -7),
                end: nthDayAfterDate(dateRange.end, -7),
            });
        }
        else {
            const firstDay = increaseMonths(dateRange.start, -1);
            const lastDay = nthDayAfterDate(firstDay, getMonthLength(firstDay) - 1);
            setDateRange({
                start: firstDay,
                end: lastDay
            });
            setNumberOfDays(getMonthLength(firstDay));
        }
    }
    function nextPeriod() {
        if (mode === 'week'){
            setDateRange({
                start: nthDayAfterDate(dateRange.start, 7),
                end: nthDayAfterDate(dateRange.end, 7),
            });
        }
        else {
            const firstDay = increaseMonths(dateRange.start, 1);
            const lastDay = nthDayAfterDate(firstDay, getMonthLength(firstDay) - 1);
            setDateRange({
                start: firstDay,
                end: lastDay
            });
            setNumberOfDays(getMonthLength(firstDay));
        }
    }

    function toggleFullScreen() {
        setFullscreen( fs => !fs);
    }

    return {
        actionsWidget,
        rowStyle,
        mode,
        monthName,
        monthNumber,
        numberOfDays,
        dateRange,
        prevPeriod,
        nextPeriod,
        formatDay,
        filters
    };
}

function formatDay(date) {
    return <>{date.getDate()} <span className='day'>{WEEK_DAYS[date.getDay()]}</span></>;
}

