import TitleWithIcon from "./TitleWithIcon";
import React, {useEffect, useRef, useState} from "react";
import "./ModalPopup.scss";
import "./TeamTaskForm.scss"
import './_interface';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";

import { registerLocale, setDefaultLocale } from  "react-datepicker";
import { pl } from 'date-fns/locale/pl';
import {formatDateTime} from "../lib/DateTools";
import {toast} from "react-toastify";
registerLocale('pl', pl);
setDefaultLocale('pl');

/**
 *
 * @param {Date} startDate
 * @param {Date} endDate
 * @param {TeamMemberInterface} teamMember
 * @param {TeamTaskInterface} task
 * @param {TeamInterface} team
 * @param {function} close
 * @return {JSX.Element}
 * @constructor
 */
export default function TeamTaskForm({startDate, endDate, teamMember, team, task, close}) {
    const [taskStartDate, setTaskStartDate] = useState(task?.startDate || startDate || new Date());
    const [taskEndDate, setTaskEndDate] = useState(task?.endDate || endDate || new Date());
    const [color, setColor] = useState(parseInt(task?.color || 0));
    const [description, setDescription] = useState(task?.description || '');
    const inputRef = useRef();

    function save() {
        let updatedTask = {};
        if (task) {
            updatedTask.id = task.id;
            updatedTask.createdBy = task.createdBy.id;
            updatedTask.createdAt = formatDateTime(new Date());
        }
        updatedTask.teamMemberId = task?.teamMemberId || teamMember?.teamMemberId;
        updatedTask.teamId = task?.teamId || teamMember?.teamId || team.id;
        updatedTask.personId = task?.personId || teamMember?.personId;
        updatedTask.startDate = formatDateTime(taskStartDate, 8);
        updatedTask.endDate = formatDateTime(taskEndDate, 18);
        updatedTask.color = color;
        updatedTask.description = description;

        if (updatedTask.id) {
            fetch(`/api/team/tasks/${updatedTask.id}`, {method: 'PUT', body: JSON.stringify(updatedTask)}).then( response => {
                if (response.ok) response.json().then(
                    data => {
                        console.log(data);
                        if (data.status === 'ok') {
                            const e = new CustomEvent('TeamTaskUpdate', {detail: {task: updatedTask, responseData: data}});
                            document.dispatchEvent(e);
                        }
                        else toast.error(data.message);
                    }
                );
                else response.error(response.statusText);
            });
        }
        else {
            fetch(`/api/team/tasks`, {method: 'POST', body: JSON.stringify(updatedTask)}).then( response => {
                if (response.ok) response.json().then(
                    data => {
                        if (data.status === 'ok') {
                            const e = new CustomEvent('TeamTaskUpdate', {detail: {task: updatedTask, responseData: data}});
                            document.dispatchEvent(e);
                        }
                        else toast.error(data.message);
                    }
                );
                else response.error(response.statusText);
            });
        }

        console.log('API POST object:', updatedTask);
        close();
    }

    function onClose() {
        const e = new CustomEvent('TeamTaskFormClose');
        document.dispatchEvent(e);
    }

    function keyUpHandler(e) {
        if (e.key === 'Enter') save();
    }

    useEffect(() => {
        inputRef.current?.focus();
    }, [color]);

    useEffect(
        () => onClose,
        []
    )
    

    return <div className="modal-popup team-task-form team">
        <div className={`person tasks_colors_${color}`}>
            {teamMember ?
                <>
                    <img className="avatar" src={teamMember.avatar} alt={teamMember.name}/>
                    <div className="name"><i className="fa fa-calendar"></i>&nbsp;{teamMember.name}</div>
                </>
                : <div className="name"><i className="fa fa-calendar"></i>&nbsp;{team.name}</div>
            }
        </div>
        <form className="content">
            <div className="form-row">
                <label htmlFor="task-description">Zadanie</label>
                <input name="task-description" value={description} onChange={(e) => setDescription(e.target.value)} onKeyUp={keyUpHandler} type="text" ref={inputRef} />
            </div>
            <div className="form-row">
                <label htmlFor="task-color">Kolor</label>
                <div className="color-grid-wrapper">
                    <div className="color-grid-group">
                        <div className="group-name">Bieżące</div>
                        <div className="color-swatches">
                            { [0,1,2].map(option => (
                                <div className={`color-swatch tasks_colors_${option}`} onClick={() => setColor(option)}>
                                    <input required="1" name="task-color" type="radio" value={option} checked={color === option}/>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="color-grid-group">
                        <div className="group-name">Wewnętrzne</div>
                        <div className="color-swatches">
                            { [3,4,5].map(option => (
                                <div className={`color-swatch tasks_colors_${option}`} onClick={() => setColor(option)}>
                                    <input required="1" name="task-color" type="radio" value={option} checked={color === option}/>
                                </div>
                            ))}                        </div>
                    </div>
                    <div className="color-grid-group">
                        <div className="group-name">Przetargi</div>
                        <div className="color-swatches">
                            { [6,7,8].map(option => (
                                <div className={`color-swatch tasks_colors_${option}`} onClick={() => setColor(option)}>
                                    <input required="1" name="task-color" type="radio" value={option} checked={color === option}/>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="color-grid-group">
                        <div className="group-name" data-tooltip="Kalendarz">
                            <i className="fa fa-calendar"></i>
                        </div>
                        <div className="color-swatches">
                            { [9].map(option => (
                                <div className={`color-swatch tasks_colors_${option}`} onClick={() => setColor(option)}>
                                    <input required="1" name="task-color" type="radio" value={option} checked={color === option}/>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className="form-row">
                <div className="form-column">
                    <label htmlFor="date-from">Początek</label>
                    <DatePicker selected={taskStartDate} onChange={(date) => setTaskStartDate(date)} dateFormat="yyyy-MM-dd"/>
                </div>
                <div className="form-column">
                    <label htmlFor="date-from">Koniec</label>
                    <DatePicker selected={taskEndDate} onChange={(date) => setTaskEndDate(date)} dateFormat="yyyy-MM-dd"/>
                </div>
            </div>
        </form>

        <div className="footer">
            <div className="negative button" onClick={close}>
                ANULUJ
            </div>

            <div className="positive button" onClick={save}>
                UTWÓRZ
            </div>
        </div>
    </div>
}
