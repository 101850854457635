import React, {useEffect, useMemo, useRef, useState} from "react";
import ConfigToggleBar from "./ConfigToggleBar";
import "./DashboardEdit.scss";
import "./ModalPopup.scss";
import {usePositionReorder} from "../hooks/use-position-reorder";
import TitleWithIcon from "./TitleWithIcon";


export default function DashboardEdit({onChanges: propagateChanges, config, close, layout}) {

    const modulesList = config;

    let [dashboardModulesConfig, setModulesConfig] = useState(modulesList);
    const initialConfig = useMemo(() => config.map(row => Object.assign({}, row)), []);
    const initialLayout = useMemo(() => layout, []);

    const constraintsRef = useRef(null)

    let [dashboardLayout, setDashboardLayout]  = useState(layout);

    function applyChanges(modules, layout) {
        setModulesConfig(modules);
        setDashboardLayout(initialLayout);
        propagateChanges(modules, initialLayout);
    }

    const [updatedList, updatePosition, updateOrder] = usePositionReorder(modulesList, applyChanges);

    function toggle (module, index) {
        module.checked = ! module.checked;
        const newModules = [...dashboardModulesConfig];
        newModules.splice(index, 1, module);
        setModulesConfig(newModules);
        propagateChanges(newModules)
    }
    function handleEscape(e) {
        if (e.key === "Escape") {
            applyChanges(initialConfig, initialLayout);
        };
    }

    function updateDashboardLayout(layout) {
        setDashboardLayout(layout);
        propagateChanges(dashboardModulesConfig, layout);
    }

    function layoutButtonClass(layout) {
        return layout === dashboardLayout ? 'button active' : 'button';
    }

    useEffect( () => {
        document.addEventListener('keydown', handleEscape);
        return () => {
            document.removeEventListener('keydown', handleEscape);
        }
    }, []);

    return <div className="modal-popup dashboardEditWrapper">
        <TitleWithIcon title="Dashboard" icon="dash"/>
        <div className="layout-setup">
            <div className="description">
                Wybierz układ dashboardu
            </div>
            <div className="buttons">
                <div className={layoutButtonClass('30:70')} onClick={() => updateDashboardLayout('30:70')}>30:70</div>
                <div className={layoutButtonClass('50:50')} onClick={() => updateDashboardLayout('50:50')}>50:50</div>
                <div className={layoutButtonClass('70:30')} onClick={() => updateDashboardLayout('70:30')}>70:30</div>
            </div>
        </div>
        <div className="description">
            Zaznacz wybrany moduł w celu dodania go do dashboardu. Możesz zmienić kolejność modułów przeciągając je przy pomocy przycisku <img src="/ui/icons-black/handle.svg" style={{verticalAlign: "middle", margin: "0 0.5em"}} alt=""/>
        </div>
        <div className="content container" ref={constraintsRef}>
            {
                updatedList.map(
                    (module, index) => {
                        return <ConfigToggleBar
                            title={module.title}
                            checked={module.checked}
                            onToggle={() => toggle(module, index)}
                            key={module.id}
                            ind={index}
                            updateOrder={updateOrder}
                            updatePosition={updatePosition}
                            constraintsRef={constraintsRef}
                        />
                    }
                )
            }
        </div>
        <div className="footer">
            <div className="button" onClick={close}>
                ZAPISZ ZMIANY
            </div>
        </div>
    </div>
}
