import React, {createContext, useEffect, useState} from "react";
import "./TeamPlanners.scss";
import BoxTeamPlanner from "./BoxTeamPlanner";
import useTeamsService from "../lib/useTeamsService";
import {Bounce, ToastContainer} from "react-toastify";
import {Tooltip} from "react-tooltip";
import 'react-tooltip/dist/react-tooltip.css';
import useSystemUsers from "../lib/useSystemUsers";

const PlannerContext = createContext({});
export default function TeamPlanners({editButton}) {

    const [dragTask, setDragTask] = useState(null);
    const { users } = useSystemUsers();
    const {teams, editView} = useTeamsService();

    useEffect(() => {
        editButton?.addEventListener('click', editView);
        return () => { editButton?.removeEventListener('click', editView) }
    }, []);

    return (
        <PlannerContext.Provider value={{dragTask, setDragTask, users}}>
            <div className='team-planners'>
                {
                    teams.map((team) => <BoxTeamPlanner team={team} key={team.id}></BoxTeamPlanner>)
                }
                <ToastContainer
                    position="bottom-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick={false}
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                    transition={Bounce}/>
                <Tooltip id="team-name-tooltip" style={{zIndex: 10}}/>
                <Tooltip id="team-tooltip-light" style={{zIndex: 10}}/>
            </div>
        </PlannerContext.Provider>
    )
}

export {PlannerContext};
