import React, {useEffect, useMemo, useRef, useState} from "react";
import "./RecentAssignments.scss";
import "./ModalPopup.scss";
import TitleWithIcon from "./TitleWithIcon";
import AssignmentRow from "./AssignmentRow";
import useImagePopup from "../hooks/use-image-popup";
import useAssignmentsEventListeners from "../hooks/useAssignmentsEventListeners";
import usePublishingAssignmentsApi from "../hooks/usePublishingAssignmentsApi";
import useDesignAssignmentsApi from "../hooks/useDesignAssignmentsApi";


export default function RecentAssignments({onChanges: propagateChanges, close}) {

    const constraintsRef = useRef(null);
    const [assignments, setAssignments] = useState([]);
    const [showPopup, closePopup] = useImagePopup();
    const [active, setActive] = useState(-1);

    const {togglePublishingAssignmentPin, openAssignment: openPublishingAssignment, requestStatusChange: requestPublishingStatusChange} = usePublishingAssignmentsApi(assignments, setAssignments);
    const {toggleDesignAssignmentPin, openDesign, requestStatusChange: requestDesignStatusChange} = useDesignAssignmentsApi(assignments, setAssignments);

    useEffect(() => {
        document.addEventListener('keydown', handleSwitching)
        document.addEventListener('keyup', handleEnter)
        document.addEventListener('keyup', handleAltUp)
        return () => {
            document.removeEventListener('keydown', handleSwitching)
            document.removeEventListener('keyup', handleEnter)
            document.removeEventListener('keyup', handleAltUp)
        };
    }, []);

    function getActive() {
        return new Promise((resolve) => {
            let currentActive;
            setActive(a => { currentActive = a; return a});
            setTimeout(function () {
                resolve(currentActive);
            })
        } )
    }

    function openAssignment(type, id) {
        switch(type) {
            case 'design':
                window.location.href = `/design/${id}/show`;
                break;
            case 'publishing':
                openPublishingAssignment(id);
                break;
        }
    }
    function handleEnter(e) {
        if (e.key === "Enter") {
            setAssignments( a => {
                getActive().then( (currentActive) => {
                    openAssignment(a[currentActive].type, a[currentActive].id);
                })
                return a;
            })
        }
    }

    function handleAltUp(e) {
        if (e.key === 'Alt' || e.key === 'Ctrl') {
            getActive().then( (currentActive) => {
                if (currentActive >=0 ) {
                    setAssignments(a => {
                        openAssignment(a[currentActive].type, a[currentActive].id);
                        return a;
                    })
                }
            });
        }
    }

    function handleSwitching(e) {
        const IS_MAC_OS = navigator.platform.startsWith('Mac');
        if (e.key === 'Tab' && e.altKey && IS_MAC_OS
            || e.key === 'Tab' && e.ctrlKey && !IS_MAC_OS
            || e.key === '`' && e.ctrlKey && !IS_MAC_OS
            || e.key === '`' && e.altKey && !IS_MAC_OS
        ) {
            let assignmentsLength;
            setAssignments(a => { assignmentsLength = a.length; return a; });

            setTimeout(function() {
                setActive(active => {
                    let newActive;
                    if (!e.shiftKey) {
                        newActive = (active + 1)%assignmentsLength;
                    }
                    else {
                        newActive = (active - 1);
                        if (newActive < 0) newActive = assignmentsLength - 1;
                    }
                    return newActive;
                });
            }, 100);

            e.preventDefault();
            e.stopPropagation();
        }
    }

    // function layoutButtonClass(layout) {
    //     return layout === dashboardLayout ? 'button active' : 'button';
    // }

    useEffect( () => {
        document.addEventListener('keydown', handleEnter);
        return () => {
            document.removeEventListener('keydown', handleEnter);
        }
    }, []);

    useEffect( () => {
        fetch('/api/assignments/my/todays', {credentials: "same-origin"})
            .then(resp => resp.json())
            .then(json => {
                setAssignments(currentAssignments => {
                    const all = [].concat(currentAssignments, json.assignments.map(a => {
                        a.type='publishing'; return a;
                    }));
                    return all.sort( (a,b) =>  a.touchedAt > b.touchedAt ? -1 : 1);
                });
            });
        fetch('/api/designs/my/todays', {credentials: "same-origin"})
            .then(resp => resp.json())
            .then(json => {
                setAssignments(currentAssignments => {
                    const all = [].concat(currentAssignments, json.assignments.map(a => {
                        a.type='design'; return a;
                    }));
                    return all.sort( (a,b) =>  a.touchedAt > b.touchedAt ? -1 : 1);
                });
            });
    }, []);

    return <div className="modal-popup recentAsignmentsWrapper">
        <TitleWithIcon title="Ostatnie zlecenia i projekty" icon="history"/>
        {/*<div className="description">*/}
        {/*    Wybierz zlecenie które chcesz otworzyć*/}
        {/*</div>*/}
        <div className="content container" ref={constraintsRef}>
            {
                assignments.map(
                    (assignment, index) => {
                        return <AssignmentRow
                            className={index === active ? 'active':''}
                            assignment={assignment}
                            essentials={true}
                            key={assignment.type+assignment.id}
                            tabindex={index}
                            open={() => openAssignment(assignment.type, assignment.id)}
                            togglePin={assignment.type === 'publishing' ? togglePublishingAssignmentPin : toggleDesignAssignmentPin }
                            changeStatus = {assignment.type === 'publishing' ? requestPublishingStatusChange : requestDesignStatusChange }
                            showPopup={showPopup}
                            closePopup={closePopup}
                            typeMarker={assignment.type === 'publishing' ? 'P' : 'D'}
                        />
                    }
                )
            }
        </div>
    </div>
}
