import {useContext, useEffect, useRef, useState} from "react";
import {SharedContext} from "../index";
import TeamsViewEdit from "../components/TeamsViewEdit";

export default function useTeamsService() {
    const [allTeams, setAllTeams] = useState([]);
    const [teams, setTeams] = useState([]);
    const [teamsCount, setTeamsCount] = useState([]);
    const [teamsConfig, setTeamsConfig] = useState([]);
    const {setModal, closeModal} = useContext(SharedContext);

    useEffect(() => {
        fetchAllTeams().then(data => {
            setAllTeams(data.teams);
            setTeamsCount(data.total)
        })
    }, []);

    useEffect(() => {
        loadConfig();
    }, [allTeams]);

    useEffect(() => {
        if (!teamsConfig || !teamsConfig.length) {
            setTeams([...allTeams]);
        }
        else {
            setFilteredTeams();
        }
    } , [teamsConfig])

    function loadConfig() {
        const teamsConfigJSON = localStorage.getItem('teamsViewConfig');
        try {
            let loadedTeamsConfig = teamsConfigJSON ? JSON.parse(teamsConfigJSON) : null;
            const config = validateConfig(loadedTeamsConfig).then(config => setTeamsConfig(config));
        }
        catch (e) {
            console.error('Error parsing dashboard config');
        }
    }

    function fetchAllTeams() {
        return new Promise( (resolve, reject) => {
            fetch('/api/teams', {credentials: "same-origin"})
                .then(resp => resp.json())
                .then(json => resolve(json));
        } )
    }

    function setFilteredTeams() {
        const visibleTeams = [];
        for(let team of teamsConfig) {
            if (team.checked) {
                const teamToAdd = allTeams.find(t => t.id === team.id);
                if (teamToAdd) visibleTeams.push(teamToAdd);
            }
        }
        console.log('VISIBLE TEAMS:');
        console.log(visibleTeams);
        setTeams( visibleTeams );
    }

    function editView() {
        setTeamsConfig( currentConfig => {
            setModal(<TeamsViewEdit config={currentConfig} onChanges={changeTeams} close={closeModal}/>);
            return currentConfig;
        })
    }

    function changeTeams(newTeamsConfig) {
        saveConfig(newTeamsConfig);
    }

    function saveConfig(teamsConfig) {
        console.log('Saving changes: ', teamsConfig);
        validateConfig(teamsConfig).then(config => {
            setTeamsConfig(config);
            localStorage.setItem('teamsViewConfig', JSON.stringify(config));
        });
    }

    function validateConfig(teamsConfig) {
        return new Promise( (resolve, reject) => {
            setAllTeams( currentAllTeams => {
                if (!currentAllTeams) return [];
                if (!Array.isArray(teamsConfig) || !teamsConfig.length) {
                    const defaultConfig = currentAllTeams.map( t => { return { id: t.id, name: t.name, checked: true } });
                    setTeamsConfig(defaultConfig);
                    resolve(defaultConfig);
                }
                else {
                    const validTeams = [];
                    for(let team of teamsConfig) {
                        const teamWithId = currentAllTeams.find(t => t.id === team.id);
                        if (teamWithId) validTeams.push(team);
                    }
                    resolve(validTeams);
                }

                return currentAllTeams;
            })
        } )
    }

    return {teams, editView};
}
