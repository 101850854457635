import {getIsoDate, timeDifferenceInMinutes} from "./DateTools";

export default class CalendarEventsGrid {

    startDate;
    endDate;
    teamMembers;

    grid = [];
    dateMap = {};

    globalEvents = {}
    unassignedTasks = [];

    /**
     *
     * @param {Date} start
     * @param {Date} end
     * @param {TeamMemberInterface[]} teamMembers
     */
    constructor(start, end, teamMembers) {
        this.startDate = new Date(start);
        this.endDate = (new Date(end)).setHours(23, 59, 59);
        this.teamMembers = teamMembers;
    }

    /**
     *
     * @param {TeamTaskInterface[]} tasks
     * @param {AbsenceInterface[]} absences
     * @param {TeamTaskInterface[]} otherTeamsTasks
     * @param {TeamMemberInterface[]} teamMembers
     * @return {*}
     */
    parseTasks(tasks, absences, otherTeamsTasks) {
        // Initialize the output array
        this.grid = this.teamMembers.map(() => []);
        let teamMemberMap = {};
        let personMap = {};

        // Generate date indices
        let dateArray = [];
        for (let d = new Date(this.startDate); d <= this.endDate; d.setDate(d.getDate() + 1)) {
            let dateStr = d.toISOString().split('T')[0];
            this.dateMap[dateStr] = dateArray.length;
            dateArray.push(dateStr);
        }

        // Generate team member indices
        teamMemberMap[0] = 0;
        personMap[0] = 0;
        this.grid[0] = dateArray.map(() => []);

        this.teamMembers.forEach((tm, index) => {
            teamMemberMap[tm.teamMemberId] = index + 1;
            personMap[tm.personId] = index + 1;
            this.grid[1 + index] = dateArray.map(() => []);
        });

        absences.forEach(absence => absence.span = countSpan(absence, this.startDate, this.endDate));
        absences = this.convertMeetingsToTask(absences);

        // const allTasks = [].concat(tasks, otherTeamsTasks);
        // if (allTasks.length === 1) allTasks[0].span = countSpan(allTasks[0], this.startDate, this.endDate);
        // allTasks.sort((a, b) => {
        //     a.span = a.span || countSpan(a, this.startDate, this.endDate);
        //     b.span = b.span || countSpan(b, this.startDate, this.endDate);
        //     const diff = b.span - a.span;
        //     return diff === 0 ? b.id - a.id : diff;
        // });

        if (tasks.length === 1) tasks[0].span = countSpan(tasks[0], this.startDate, this.endDate);
        tasks.sort((a, b) => {
            a.span = a.span || countSpan(a, this.startDate, this.endDate);
            b.span = b.span || countSpan(b, this.startDate, this.endDate);
            const diff = b.span - a.span;
            return diff === 0 ? b.id - a.id : diff;
        })

        otherTeamsTasks.forEach(task => task.initial = task.teamName?.replace(/[^\w&+_\-]/g,' ').split(/ +/).map(word => word.substring(0,1)).join(''));

        if (otherTeamsTasks.length === 1) otherTeamsTasks[0].span = countSpan(otherTeamsTasks[0], this.startDate, this.endDate);
        otherTeamsTasks.sort((a, b) => {
            a.span = a.span || countSpan(a, this.startDate, this.endDate);
            b.span = b.span || countSpan(b, this.startDate, this.endDate);
            const diff = b.span - a.span;
            return diff === 0 ? b.id - a.id : diff;
        })

        // Process absences
        absences.forEach( absence => this.insertEventToGrid(absence, personMap[absence.personId || 0]));
        tasks.forEach(task => this.insertEventToGrid(task, teamMemberMap[task.teamMemberId || 0]));
        otherTeamsTasks.forEach(task => this.insertEventToGrid(task, personMap[task.personId || 0]));
        // allTasks.forEach(task => this.insertEventToGrid(task, 'initial' in task ? personMap[task.personId] : teamMemberMap[task.teamMemberId]));

        return this.grid;
    }

    insertEventToGrid(event, row) {
        if (event === undefined || row === undefined) return;
        let eventStart = new Date(event.startDate);
        let eventEnd = new Date(event.endDate);

        let started = false;
        let firstIndex = -1;

        for (let d = new Date(eventStart); d <= eventEnd; d.setDate(d.getDate() + 1)) {
            let dateStr = d.toISOString().split('T')[0];

            if (this.dateMap.hasOwnProperty(dateStr)) {
                let eventCopy = {...event, continued: started || eventStart < this.startDate, continues: d < eventEnd};

                if (!row && !eventCopy.teamId) {
                    this.globalEvents[dateStr] = eventCopy;
                }
                else {
                    if (!started) {
                        eventCopy.first = true;
                        let firstIndexes = [];
                        for (let i = 0; i < eventCopy.span; i++) firstIndexes.push(this.grid[row][this.dateMap[dateStr] + i].length); // TODO: index of empty element
                        firstIndex = Math.max(...firstIndexes);
                        eventCopy.continues = eventEnd > this.endDate;
                    }
                    this.grid[row][this.dateMap[dateStr]][firstIndex] = eventCopy;
                    started = true;
                }
            }
        }
    }

    fillNullTaskSlots() {
        this.grid = this.grid.map(memberTasks => memberTasks.map(dayTasks => fillEmptyArrayElements(dayTasks, {})));
        return this.grid;
    }

    getGrid() {
        return this.grid;
    }

    /**
     *
     * @param {AbsenceInterface[]} absences
     */
    convertMeetingsToTask(absences) {
        const newAbsences = [];
        for (let i=0; i<absences.length; i++) {
            let absence = absences[i];
            if (absence.type !== 'meeting') {
                newAbsences.push(absence);
            }
            else {
                const agregator = {
                    id: 'aggregated_'+absence.id,
                    type: 'meetings',
                    startDate: absence.startDate,
                    endDate: absence.endDate,
                    personId: absence.personId,
                    meetingsList: [
                        absence
                    ],
                    span: countSpan(absence, this.startDate, this.endDate),
                    duration: timeDifferenceInMinutes(absence.startDate, absence.endDate)
                }

                while ( isSameDayAndPersonMeeting(absence, absences[i+1]) ) {
                    agregator.meetingsList.push(absences[i+1]);
                    agregator.duration += timeDifferenceInMinutes(absences[i+1].startDate, absences[i+1].endDate);
                    i += 1;
                }

                const length = agregator.meetingsList.length;
                const noun = length === 1 ? 'spotkanie' : (length < 5 ? 'spotkania' : 'spotkań')
                const durationFormatted = Math.floor(agregator.duration / 60) + ':' + String('0'+(agregator.duration % 60)).slice(-2);
                agregator.description = `${length} ${noun} – <span class="emoji">⏱️</span> ${durationFormatted}`;

                newAbsences.push(agregator);
            }
        }
        return newAbsences;
    }
}

function fillEmptyArrayElements(arr, filler) {
    for (let i = 0; i < arr.length; i++) {
        arr[i] = arr[i] === undefined ? filler : arr[i];
    }
    return arr;
}

/**
 *
 * @param {AbsenceInterface} absence1
 * @param {AbsenceInterface} absence2
 */
function isSameDayAndPersonMeeting(absence1, absence2) {
    if (!absence1 || !absence2) return false;
    return absence1.type === absence2.type
        && absence1.personId === absence2.personId
        && getIsoDate(absence1.startDate) === getIsoDate(absence2.startDate)
        && getIsoDate(absence1.endDate) === getIsoDate(absence2.endDate);
}

function countSpan(task, start, end) {
    let taskStart = new Date(task.startDate);
    let taskEnd = new Date(task.endDate);

    let spanStart = start ? new Date(Math.max(taskStart, start)) : taskStart;
    let spanEnd = end ? new Date(Math.min(taskEnd, end)) : taskEnd;

    let span = 0;

    for (let temp = new Date(spanStart); temp <= spanEnd; temp.setDate(temp.getDate() + 1)) {
        span++;
    }
    return span;
}

export {countSpan, fillEmptyArrayElements}
