import "./AssignmentStatusPopup.scss";
import "./ModalPopup.scss"
import AppContext from "../AppContext";
import TitleWithIcon from "./TitleWithIcon";
import React from "react";

export default function OfferStatusPopup({offer, onSelect: handleSelect}) {
    const appContext = AppContext.getInstance();
    return (
        <div className="modal-popup assignment_status_popup">
            <TitleWithIcon title="Status kosztorysu" icon="numbers"/>
            <div className="content container">
                { appContext.value.offerStatuses.map(
                    status => (
                        <div className={offer.status === status.slug ? 'active row' : 'row'} onClick={() => handleSelect(offer.id, status.slug)}>
                            <div className="dot" style={{background: status.color}}></div>
                            {status.name}
                        </div>
                    )
                )}
            </div>
        </div>
    )
}
