import React, {createContext, useContext, useEffect, useState} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';

import Dashboard from "./Dashboard";
import PublishingAssignmentsBrowser from "./components/PublishingAssignmentsBrowser";
import AppContext from "./AppContext";
import DesignAssignmentsBrowser from "./components/DesignAssignmentsBrowser";
import ModalTarget from "./components/ModalTarget";
import BoxTasks from "./components/BoxTasks";
import NotificationsModal from "./components/NotificationsModal";
import OffersBrowser from "./components/OffersBrowser";
import ArchiveBrowser from "./components/ArchiveBrowser";
import Notifications from "./components/Notifications";
import BoxWrapper from "./components/BoxWrapper";
import AssignmentNotifications from "./components/AssignmentNotifications";
import useAllNotificationsService from "./hooks/useAllNotificationsService";
import BoxNotifications from "./components/BoxNotifications";
import ClientsPublishingAssignmentsBrowser from "./components/ClientsPublishingAssignmentsBrowser";
import ClientsDesignAssignmentsBrowser from "./components/ClientsDesignAssignmentsBrowser";
import AnimatesBrowser from "./components/AnimatesBrowser";
import PreviewsBrowser from "./components/PreviewsBrowser";
import RecentAssignments from "./components/RecentAssignments";
import DashboardEdit from "./components/DashboardEdit";
import RecentAssignmentsModal from "./components/RecentAssignmentsModal";
import BoxPlanner from "./components/BoxPlanner";
import TeamPlanners from "./components/TeamPlanners";


const rootForHome = document.querySelector('#home');
const appContext = AppContext.getInstance();
const SharedContext = createContext(null);
const NotificationsContext = createContext(null);


appContext.setValue({
    statuses: [
        {name: 'Draft', color: '#CECECE', slug: 'draft'},
        {name: 'Czeka', color: '#FFBE00', slug: 'czeka'},
        {name: 'W trakcie realizacji', color: '#08B73E', slug: 'w-trakcie-realizacji'},
        {name: 'Zrobione', color: '#157FCE', slug: 'zrobione'},
        {name: 'Przetarg przegrany', color: '#8131B5', slug: 'przetarg-przegrany'},
        {name: 'Wstrzymane', color: '#D41C1C', slug: 'wstrzymane'},
        {name: 'Anulowane', color: '#22222C', slug: 'anulowane', border: true},
    ],
    statusFilter: {
        'draft': true,
        'czeka': true,
        'w-trakcie-realizacji': true,
        'zrobione': false,
        'przetarg-przegrany': true,
        'wstrzymane': true,
        'anulowane': false
    },
    offerStatuses: [
        {name: 'template', color: '#fd00ef', slug: 'template'},
        {name: 'Draft', color: '#5d5d5d', slug: 'draft'},
        {name: 'Zaakceptowany przez szefa', color: '#ffff00', slug: 'confirmed'},
        {name: 'Wysłany do Klienta', color: '#d4c900', slug: 'sent'},
        {name: 'Zaakceptowany przez Klienta', color: '#68b162', slug: 'approved'},
        {name: 'Zrealizowany', color: '#008DFD', slug: 'accomplished'},
        {name: 'Przetarg przegrany', color: '#8131B5', slug: 'lost'},
        {name: 'Anulowany', color: '#22222C', slug: 'canceled', border: true},
    ],
    archiveTags: [
        {name: 'ATL', color: '#ffabae', slug: 'atl', id: 1},
        {name: 'BTL', color: '#b4fbb2', slug: 'btl', id: 2},
        {name: 'Interactive', color: '#9dd4fb', slug: 'interactive', id: 3},
        {name: 'Packaging', color: '#fbda9d', slug: 'packaging', id: 4}
    ],
    archiveTagsFilter: {
        'atl': true,
        'btl': true,
        'interactive': true,
        'packaging': true
    },
    offerStatusesFilter: {
        'template': true,
        'draft': true,
        'confirmed': true,
        'sent': true,
        'approved': true,
        'accomplished': true,
        'lost': true,
        'canceled': true,
    },
    companies: [
        {id: 1, name: "Opus B (Sp. J.)"},
        {id: 2, name: "Opus B (A. R. W.)"}
    ],
    archiveType: [
        {id: 1, name: "Przetarg"},
        {id: 2, name: "Zlecenie"}
    ]
});

(async () => {
    const resp = await fetch('/api/users/me');
    if (!resp.ok) return;
    const data = await resp.json()
    appContext.setValue({...appContext.value, loggedInUser: data})
    document.dispatchEvent(new Event('loggedInUserDataLoaded'));
})();

if (rootForHome) {
    const reactHomeNode = ReactDOM.createRoot(rootForHome);
    const editDashboardButton = document.querySelector('.header .actions .button');
    reactHomeNode.render(<React.StrictMode>
        <ModalTarget><Dashboard node={rootForHome} editButton={editDashboardButton}/></ModalTarget>
    </React.StrictMode>)
}

{
    const rootForPublishingAssignments= document.querySelector('#publishing-assignments');
    const range = rootForPublishingAssignments?.dataset?.range;
    if (rootForPublishingAssignments) {
        const reactHomeNode = ReactDOM.createRoot(rootForPublishingAssignments);
        reactHomeNode.render(
            <React.StrictMode>
                <ModalTarget><PublishingAssignmentsBrowser range={range}/></ModalTarget>
            </React.StrictMode>
        )
    }
}

{
    const rootForClientsPublishingAssignments= document.querySelector('#clients-publishing-assignments');
    const range = rootForClientsPublishingAssignments?.dataset?.range;
    const client = rootForClientsPublishingAssignments?.dataset?.client;
    if (rootForClientsPublishingAssignments) {
        const reactHomeNode = ReactDOM.createRoot(rootForClientsPublishingAssignments);
        reactHomeNode.render(
            <React.StrictMode>
                <ModalTarget><ClientsPublishingAssignmentsBrowser range={range} client={client}/></ModalTarget>
            </React.StrictMode>
        )
    }
}

{
    const rootForClientsDesignAssignments= document.querySelector('#clients-design-assignments');
    const range = rootForClientsDesignAssignments?.dataset?.range;
    const client = rootForClientsDesignAssignments?.dataset?.client;
    if (rootForClientsDesignAssignments) {
        const reactHomeNode = ReactDOM.createRoot(rootForClientsDesignAssignments);
        reactHomeNode.render(
            <React.StrictMode>
                <ModalTarget><ClientsDesignAssignmentsBrowser range={range} client={client}/></ModalTarget>
            </React.StrictMode>
        )
    }
}

{
    const rootForDesignAssignments= document.querySelector('#design-assignments');
    const range = rootForDesignAssignments?.dataset?.range;
    if (rootForDesignAssignments) {
        const reactHomeNode = ReactDOM.createRoot(rootForDesignAssignments);
        reactHomeNode.render(
            <React.StrictMode>
                <ModalTarget><DesignAssignmentsBrowser range={range}/></ModalTarget>
            </React.StrictMode>
        )
    }
}

{
    const rootForOffers= document.querySelector('#offers-browser');
    const range = rootForOffers?.dataset?.range;
    if (rootForOffers) {
        const reactHomeNode = ReactDOM.createRoot(rootForOffers);
        reactHomeNode.render(
            <React.StrictMode>
                <ModalTarget><OffersBrowser range={range}/></ModalTarget>
            </React.StrictMode>
        )
    }
}

{
    const rootForArchive= document.querySelector('#archive-browser');
    const range = rootForArchive?.dataset?.range;
    if (rootForArchive) {
        const reactHomeNode = ReactDOM.createRoot(rootForArchive);
        reactHomeNode.render(
            <React.StrictMode>
                <ModalTarget><ArchiveBrowser range={range}/></ModalTarget>
            </React.StrictMode>
        )
    }
}

{
    const rootForAnimates= document.querySelector('#animates-browser');
    const range = rootForAnimates?.dataset?.range;
    if (rootForAnimates) {
        const reactHomeNode = ReactDOM.createRoot(rootForAnimates);
        reactHomeNode.render(
            <React.StrictMode>
                <ModalTarget><AnimatesBrowser range={range}/></ModalTarget>
            </React.StrictMode>
        )
    }
}

{
    const rootForMyTasks = document.querySelector('#my-tasks');
    if (rootForMyTasks) {
        const reactHomeNode = ReactDOM.createRoot(rootForMyTasks);
        reactHomeNode.render(
            <React.StrictMode>
                <ModalTarget><BoxTasks/></ModalTarget>
            </React.StrictMode>
        )
    }
}

{
    const rootForNotifications = document.querySelector('#notifications-modal');
    if (rootForNotifications) {
        const reactNotificationsNode = ReactDOM.createRoot(rootForNotifications);
        reactNotificationsNode.render(
            <React.StrictMode>
                <ModalTarget><NotificationsModal/></ModalTarget>
            </React.StrictMode>
        )
    }
}

{
    const rootForMyNotifications = document.querySelector('#my-notifications');
    if (rootForMyNotifications) {
        const reactMyNotificationsNode = ReactDOM.createRoot(rootForMyNotifications);
        reactMyNotificationsNode.render(
            <React.StrictMode>
                <ModalTarget><BoxNotifications/></ModalTarget>
            </React.StrictMode>
        )
    }
}

{
    const rootForAssignmentNotifications = document.querySelector('#assignments-notifications');
    if (rootForAssignmentNotifications) {
        const reactAssignmentNotificationsNode = ReactDOM.createRoot(rootForAssignmentNotifications);
        reactAssignmentNotificationsNode.render(
            <React.StrictMode>
                <ModalTarget><AssignmentNotifications assignmentType={rootForAssignmentNotifications.dataset.assignmentType} assignmentId={rootForAssignmentNotifications.dataset.assignmentId}></AssignmentNotifications></ModalTarget>
            </React.StrictMode>
        )
    }
}

{
    const rootForReactPreviews = document.querySelector('#react-previews');
    if (rootForReactPreviews) {
        const reactPreviewNode = ReactDOM.createRoot(rootForReactPreviews);
        reactPreviewNode.render(
            <React.StrictMode>
                <ModalTarget><PreviewsBrowser previews={JSON.parse(rootForReactPreviews.dataset.previews)}></PreviewsBrowser></ModalTarget>
            </React.StrictMode>
        )
    }
}

{
    const rootForSwitchRecents = document.querySelector('#recents');
    if (rootForSwitchRecents) {
        const reactRecentsNode = ReactDOM.createRoot(rootForSwitchRecents);
        reactRecentsNode.render(
            <React.StrictMode>
                <ModalTarget><RecentAssignmentsModal><RecentAssignments></RecentAssignments></RecentAssignmentsModal></ModalTarget>
            </React.StrictMode>
        )
    }


}

{
    const rootForTeamsPlanner = document.querySelector('#teams-planner');
    if (rootForTeamsPlanner) {
        const teamsPlannerNode = ReactDOM.createRoot(rootForTeamsPlanner);
        const teamViewEditButton = document.querySelector('.header .button.edit-view');
        teamsPlannerNode.render(
            <React.StrictMode>
                <ModalTarget><TeamPlanners editButton={teamViewEditButton}></TeamPlanners></ModalTarget>
            </React.StrictMode>
        )
    }


}


// let reactBoxPublishingAssignments = document.body.querySelector('.react_box_publishing_assignments');
// if (reactBoxPublishingAssignments) {
//     const rootForPublishingAssignments = ReactDOM.createRoot(reactBoxPublishingAssignments);
//     rootForPublishingAssignments.render(<React.StrictMode><BoxPublishingAssignments /></React.StrictMode>);
// }
//
// let reactBoxDesignAssignments = document.body.querySelector('.react_box_design_assignments');
// if (reactBoxDesignAssignments) {
//     const rootForDesignAssignments = ReactDOM.createRoot(reactBoxDesignAssignments);
//     rootForDesignAssignments.render(<React.StrictMode><BoxDesignAssignments /></React.StrictMode>);
// }
//
// let reactBoxNotifications = document.body.querySelector('.react_box_notifications');
// if (reactBoxNotifications) {
//     const rootForBoxNotifications = ReactDOM.createRoot(reactBoxNotifications);
//     rootForBoxNotifications.render(<React.StrictMode><BoxNotifications /></React.StrictMode>)
// }
//
// let reactBoxTasks = document.body.querySelector('.react_box_tasks');
// if (reactBoxTasks) {
//     const rootForBoxTasks = ReactDOM.createRoot(reactBoxTasks);
//     rootForBoxTasks.render(<React.StrictMode><BoxTasks /></React.StrictMode>)
// }
//
// let reactBoxPlanner = document.body.querySelector('.react_box_planner');
// if (reactBoxPlanner) {
//     const rootForBoxPlanner = ReactDOM.createRoot(reactBoxPlanner);
//     rootForBoxPlanner.render(<React.StrictMode><BoxPlanner /></React.StrictMode>)
// }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

export {AppContext, SharedContext};
